import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'
import type { HeadingProps, NarrowText } from './types'
import { Text } from './text'

const Heading = forwardRef(
  (
    { level = 1, ...props }: HeadingProps,
    ref: ForwardedRef<HTMLHeadingElement>,
  ) => <Text ref={ref} as={`h${level}` as const} {...props} />,
)

Heading.displayName = 'Heading'

export const H1 = forwardRef(
  ({ ...props }: NarrowText, ref: ForwardedRef<HTMLHeadingElement>) => (
    <Heading level={1} ref={ref} {...props} />
  ),
)
export const H2 = forwardRef(
  ({ ...props }: NarrowText, ref: ForwardedRef<HTMLHeadingElement>) => (
    <Heading level={2} ref={ref} {...props} />
  ),
)

export const H3 = forwardRef(
  ({ ...props }: NarrowText, ref: ForwardedRef<HTMLHeadingElement>) => (
    <Heading level={3} ref={ref} {...props} />
  ),
)

export const H4 = forwardRef(
  ({ ...props }: NarrowText, ref: ForwardedRef<HTMLHeadingElement>) => (
    <Heading level={4} ref={ref} {...props} />
  ),
)

export const H5 = forwardRef(
  ({ ...props }: NarrowText, ref: ForwardedRef<HTMLHeadingElement>) => (
    <Heading level={5} ref={ref} {...props} />
  ),
)

const H6 = forwardRef(
  ({ ...props }: NarrowText, ref: ForwardedRef<HTMLHeadingElement>) => (
    <Heading level={6} ref={ref} {...props} />
  ),
)

H1.displayName = 'H1'
H2.displayName = 'H2'
H3.displayName = 'H3'
H4.displayName = 'H4'
H5.displayName = 'H5'
H6.displayName = 'H6'
